<div [ngBusy]="{busy: busy, message: '', backdrop: false, delay: 0, minDuration: 200}"></div>

<div class="row">
  <div class="col-xl-12 col-md-12">
    <div class="main-search-panel">

      <div class="top-panel">
        Ricerca estrazioni per periodo
      </div>
      <form [formGroup]="form" autocomplete="off" name="ricerca">
        <div class="search-action-box" id="ab1">
          <div class="row-container">
            <div class="title-container transition-box">
              <h5 class="title" [@enterAnimation]>
                {{messaggio}}
              </h5>
            </div>
            <fieldset class="field-container">
              <!-- <input type="text" placeholder="Datepicker" class="form-control" [maxDate]="maxDate" [bsConfig]="bsConfig" bsDatepicker> -->
              <div class="input-group mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="weekMonth" id="tipo_periodo1"
                    value="week" checked>
                  <label class="form-check-label" for="tipo_periodo1">
                    Settimana
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="weekMonth" id="tipo_periodo2"
                    value="month">
                  <label class="form-check-label" for="tipo_periodo2">
                    Mese
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset class="field-container">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Periodo</span>
                </div>
                <input class="form-control" bsDatepicker formControlName="querySTART" name="querySTART"
                  [bsConfig]="bsConfig" placeholder="DATA inizio" [minDate]="minDate" [maxDate]="startMaxDate"
                  [daysDisabled]="[2,3,4,5,6,0]" (bsValueChange)="onStartDateChange($event)"
                  (onShown)="onOpenCalendar($event)">

                <input class="form-control" bsDatepicker formControlName="queryEND" name="queryEND"
                  [bsConfig]="bsConfig" placeholder="Data fine" [minDate]="endMinDate" [maxDate]="maxDate"
                  [daysDisabled]="[1,2,3,4,5,6]" (bsValueChange)="onEndDateChange($event)"
                  (onShown)="onOpenCalendar($event)">
              </div>
            </fieldset>
            <fieldset class="field-container">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">Report</label>
                </div>
                <select id="inputState" formControlName="families" name="families" class="form-control"
                  (change)="onSelectFamily($event)" placeholder="Famiglia prodotti">
                  <option value="0" disabled selected="selected" name="families1">--</option>
                  <option [value]="reportEnum.PRODOTTI" selected="selected" name="families1">PRODOTTI</option>
                  <option [value]="reportEnum.OPERATORE" selected="selected" name="families1">OPERATORE</option>
                  <option [value]="reportEnum.REPARTO" selected="selected" name="families1">DI REPARTO</option>
                </select>
              </div>
            </fieldset>
          </div>

          <div class="row-container">
            <fieldset class="field-container">
              <button type="submit" class="btn btn-info btn-app btn-block " [@enterAnimation] (click)="onClickCerca()"
                [disabled]="form.status=='INVALID'">
                <i class="fas fa-search"></i> &nbsp;Cerca!</button>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row" (window:resize)="onResize($event)">

  <div class="col-12">
    <button type="button" class="btn btn-warning" (click)="export()">Esporta</button>
  </div>
  <div class="col-12">
    <div class="selected-column" *ngIf="COLS?.length>0">
      <h5>Colonne</h5>
      <ul>
        <li *ngFor="let col of COLS">
          <input type="checkbox" [id]="col.name" (click)="toggle(col)" [checked]="isChecked(col)" />
          <label [attr.for]="col.name">{{ col.name }}</label>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-12">
    <span class="form-inline">
      <input class="form-control" type="text" style="padding:8px;margin:15px auto;width:10%;"
        placeholder="Filtro azione..." (keyup)="updateFilter($event, 'azione')" />
      <input class="form-control" type="text" style="padding:8px;margin:15px auto;width:20%;"
        placeholder="Filtro prodotto..." (keyup)="updateFilter($event, 'prodotto')" />
      <input class="form-control" type="text" style="padding:8px;margin:15px auto;width:20%;"
        placeholder="Filtro centrodicosto..." (keyup)="updateFilter($event, 'centrodicosto')" />
      <input class="form-control" type="text" style="padding:8px;margin:15px auto;width:20%;"
        placeholder="Filtro codiceoperatore..." (keyup)="updateFilter($event, 'codiceoperatore')" />
      <input class="form-control" type="text" style="padding:8px;margin:15px auto;width:15%;"
        placeholder="Filtro utente..." (keyup)="updateFilter($event, 'utente')" />
    <button type="button" class="btn btn-default" (click)="filterClear()">Reset</button>

    </span>
  </div>

  <div class="col-xl-12 col-md-12" *ngIf="tableData">
    <!-- [columns]="tableData?.columns" -->
    <ngx-datatable #myTable class="material expandable" [rows]="tableData?.payload" [columnMode]="ColumnMode.flex"
      [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="25">
      <!-- Row Detail Template -->
      <ngx-datatable-row-detail [rowHeight]="40" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="padding-left:60px; font-size:14px">
            <div>{{ row.ordine }}, {{ row.prodotto }}, {{ row.utente }}</div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <!-- Column Templates -->
      <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
        [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)" class="desktop-hidden">
          </a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="item.name" [flexGrow]="1" *ngFor="let item of tableData?.columns"
        [draggable]="false" [class]="mobileHidden(item.name)" [prop]="item.name">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span [class]="mobileHidden(item.name)">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span [class]="mobileHidden(item.name)">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </div>
</div>