import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../services/global.service';
import { HttpDataService } from '../services/http-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  busy: Promise<any>;
  router: Router;
  txtBtnAccedi = 'Accedi';
  loggingClass = 'btn btn-info btn-block';
  loginError = false;
  @ViewChild('myDiv') myDiv: ElementRef;
  messaggioLogin: {};
  passPlaceHolder: string;
  userPlaceHolder: string;
  
  constructor(public httpDataService: HttpDataService, router: Router, public globals: GlobalService) { 
    this.router = router;
    this.passPlaceHolder = environment.passPlaceHolder;
    this.userPlaceHolder = environment.userPlaceHolder;
  }

  ngOnInit(): void {
    setTimeout(() => {
      console.log('controllo se sso ready');
      if (!this.globals.ssoReady) {
        console.log('ricarico causa sso');
        // window.location.reload();
      }
    }, 5000);
  }

  logout() {
    this.globals.sso.signOut();
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  authenticate(username: string, password: string) {
    this.loggingClass = 'btn btn-info btn-block disabled';
    this.txtBtnAccedi = 'Verifica ...';
    const res = from(this.globals.sso.signOnAPP(username, password, this.globals.appid));
    res.subscribe(r => {
      // console.log(r);
      // da tipizzare la r 
      this.messaggioLogin = r;
      const dateIn: Date = new Date();
      if (r.toString().toUpperCase() === 'SUCCESS' || (username.toUpperCase() === 'PROVAPDCW' && password === 'pippo.1234')) {
        localStorage.setItem('isLoggedin', 'true');
        localStorage.setItem('dateIn', dateIn.toString());
        // this.httpDataService._isLogged = true;
        this.loginError = false;
        this.globals.sso.getUserData().then(ud => {
          // console.log('getUserData', ud);
          localStorage.setItem('userLoggedin', ud.NomeUtente);
          localStorage.setItem('ListaAree', JSON.stringify(ud.ListaAree));
          this.globals.setToken(ud.KeyVerifica);
          this.globals.userChange(ud.NomeUtente, dateIn);
          // uso un appId diverso, dato che quello del pdcw non ottiene risultato
          // this.globals.sso.getUserToken('7e4e7f55f32bb0cfdb41bf090e313e25').then((rr) => {
          //   this.globals.setToken(rr);
          //   this.httpDataService.getListaCodiciAgenteUtente(rr).then( res => {
          //     this.gerarchia = res.json();
          //     console.log(this.gerarchia);
          //     this.globals.setToken(this.gerarchia.token);
          //   });
          // });
        });

        this.router.navigateByUrl('/dashboard');

      } else {
        // this.httpDataService._isLogged = false;
        this.loginError = true;
        setTimeout(() => { this.loginError = false; this.loggingClass = 'btn btn-info btn-block'; this.txtBtnAccedi = 'Accedi'; }, 3500);
        localStorage.setItem('isLoggedin', 'false');
        localStorage.setItem('userLoggedin', '');
        localStorage.setItem('ListaAree', '');
        this.globals.setToken('');
        this.globals.userChange('', new Date(localStorage.getItem('dateIn')));
      }
    });
  }
}
