import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpDataService } from './http-data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  url_base = environment.httpDataUrl;
  constructor(public api: HttpDataService) {

  }

  report(id, data_da, data_a): Observable<any> {
    return this.api.get(this.url_base + `/report/${id}/${data_da}/${data_a}`);
  }
}
