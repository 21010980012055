import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalService } from './services/global.service';
import { SSOService } from './services/sso.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'monitoraggio-produzione';
  subscription: any;
  userlogged: any;

  constructor(
    private gs: GlobalService,
    private router: Router,
    private sso: SSOService
  ) {
    this.gs.sso = this.sso;
  }

  ngOnInit(): void {
    this.gs.ssoReady = false;

    if (localStorage.getItem('isLoggedin') === 'false') {
      this.router.navigateByUrl('/login');
    }
    this.router.events.subscribe((event) => {
      this.gs.navPush(this.router.url);
      if (event instanceof NavigationEnd) {
        const dateIn = localStorage.getItem('dateIn');
        const time = new Date().getTime() - new Date(dateIn).getTime();
        this.gs.sso.checkToken(this.gs.appid).then(check => {
          this.gs.ssoReady = true;
          // console.log('CHECK', check);
          if (check !== true) {
            this.logout();
          } 
          // else {
            // this.router.navigateByUrl('/dashboard');
          // }
        });
        if (dateIn === '' || time > 7200000 || time == NaN) {
          localStorage.setItem('isLoggedin', 'false');
          // ##FRANZ ## qui si dovrebbe fare un subscribe a un evento di attesa soluzione promise della sso_window
          // console.log('checkToken');

        }
        if (localStorage.getItem('isLoggedin') !== 'true' || localStorage.getItem('userLoggedin') == null) {
          this.logout();
        }
      }
    });
    this.userlogged = this.gs.getUsername();
    this.subscription = this.gs.userEvent.subscribe(x => {
      this.userlogged = x.username.toUpperCase();
    });
  }

  logout() {
    localStorage.setItem('dateIn', '');
    localStorage.setItem('isLoggedin', 'false');
    localStorage.setItem('userLoggedin', '');
    this.gs.setToken('');
    this.gs.sso.checkToken(this.gs.appid).then(() => {
      this.gs.sso.signOut();
    });
    this.gs.sso.signOut();
    this.router.navigateByUrl('/login');
  }


}
