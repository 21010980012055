import { Injectable, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SSOService } from './sso.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // public sso: any; // FONDAMENTALE mantenerlo globale qui
  // public readonly appid = '2195b672439692d2948f90182351294c';
  public sso: SSOService; // FONDAMENTALE mantenerlo globale qui
  public ssoReady: boolean;
  token: string;
  private navStack: Array<string> = [];
  private fd: number;
  private fm: number;
  private fy: number;
  private td: number;
  private tm: number;
  private ty: number;
  private searchRagsoc = '';
  private searchCodpsi = '';
  private searchArea = '';
  private searchAgente = '';

  public readonly appid = '8f12faca9d11b289d39620d9a51d9f61';
  // 58b3c81dd847658f896d2d9ae62588ad 2195b672439692d2948f90182351294c 8f27bfff1cd1f5963da1e29a9d003e31

  @Output()
  communicate: EventEmitter<any> = new EventEmitter();
  dateChange: EventEmitter<any> = new EventEmitter();
  userEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.token = '';
  }

  getDataFrom() {
    if (localStorage.getItem('fd')) {
      this.fd = parseInt(localStorage.getItem('fd'), 10);
      this.fm = parseInt(localStorage.getItem('fm'), 10);
      this.fy = parseInt(localStorage.getItem('fy'), 10);
    } else if (this.fd === undefined) {
      const o = moment().subtract(7, 'days');
      this.fd = moment(o).date();
      this.fm = moment(o).month() + 1;
      this.fy = moment(o).year();
    }
    return { fd: this.fd, fm: this.fm, fy: this.fy };
  }

  getDataTo() {
    if (localStorage.getItem('td')) {
      this.td = parseInt(localStorage.getItem('td'), 10);
      this.tm = parseInt(localStorage.getItem('tm'), 10);
      this.ty = parseInt(localStorage.getItem('ty'), 10);
    } else if (this.td === undefined) {
      const o = moment().subtract(1, 'days');
      this.td = moment(o).date();
      this.tm = moment(o).month() + 1;
      this.ty = moment(o).year();
    }
    return { td: this.td, tm: this.tm, ty: this.ty };
  }

  setSearchParams(r, c, a, g) {
    this.searchRagsoc = r;
    this.searchCodpsi = c;
    this.searchArea = a;
    this.searchAgente = g;
  }

  getSearchParms() {
    return { ragsoc: this.searchRagsoc, codpsi: this.searchCodpsi, area: this.searchArea, agente: this.searchAgente };
  }

  setDataFrom(fd, fm, fy) {
    this.fd = fd;
    this.fm = fm;
    this.fy = fy;
    localStorage.setItem('fd', fd);
    localStorage.setItem('fm', fm);
    localStorage.setItem('fy', fy);

  }

  setDataTo(td: number, tm: number, ty: number) {
    this.td = td;
    this.tm = tm;
    this.ty = ty;
    localStorage.setItem('td', td.toString());
    localStorage.setItem('tm', tm.toString());
    localStorage.setItem('ty', ty.toString());
  }

  getToken(): string {
    return this.token;
  }

  setToken(s: string): void {
    this.token = s;
    localStorage.setItem('token', s);
  }

  getUsername(): string {
    return localStorage.getItem('userLoggedin');
  }

  userChange(u: string, d: Date) {
    this.userEvent.emit({ username: u, dateIn: d });
  }

  displayMessage(msg: string, type: string) {
    this.communicate.emit({ m: msg, t: type });
  }

  dateChangeMessage(fd: number, fm: number, fy: number, td: number, tm: number, ty: number) {
    this.setDataFrom(fd, fm, fy);
    this.setDataTo(td, tm, ty);
    this.dateChange.emit({ fd, fm, fy, td, tm, ty });
  }

  navPush(url: string) {
    if (this.navStack.length > 5) {
      this.navStack.shift();
    }
    this.navStack.push(url);
  }

  navPop(): string {
    this.navStack.pop(); // torno in dietro (ultimo è attuale)
    return this.navStack.pop();
  }
}
