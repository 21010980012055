<div [ngBusy]="{busy: [busy], message: 'Loading...', backdrop: false, delay: 0, minDuration: 600}"></div>
<div class="login-page" #myDiv>
  <div class="account-wall">
    <img class="logo-img" src="assets/images/logo.png" alt="Sì!4Web">
    <h3>Monitoraggio della produzione</h3>
    <form class="form-signin" action="" method="">
      <input type="text" #username class="form-control login-form" placeholder="username" required autofocus [value]='userPlaceHolder'>
      <input type="password" #password class="form-control login-form" placeholder="Password" required [value]='passPlaceHolder' (keyup.enter)="authenticate(username.value, password.value)">
      <a [class]="loggingClass" [class.disabled]="!globals.ssoReady" (click)="authenticate(username.value, password.value)"> {{txtBtnAccedi}} </a>
    </form>
    <span *ngIf="loginError" style="color: red;">Login errato</span>
  </div>

</div>
