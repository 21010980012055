import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { GlobalService } from './services/global.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public globals: GlobalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.globals.getToken()) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: `Bearer ${this.globals.getToken()}`
        }
      });
    }
    // return next.handle(request).pipe(
    //   map((event: HttpEvent<any>) => {
    //       if (event instanceof HttpResponse) {
    //           console.log('event--->>>', event);
    //       }
    //       return event;
    //   }));
    return next.handle(request);
  }
}
