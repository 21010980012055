import { Injectable } from '@angular/core';
import { fromEvent as observableFromEvent, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class SSOService {

    sso_window;

    private dispatchRequest(functionName: string, params: any[]): Promise<any> {
        if (!this.sso_window) {
            return observableFromEvent(window, 'pagineSiSSOReady').pipe(
                take(1))
                .toPromise()
                .then(() => {
                    this.sso_window = (window as any);
                    return this.evadeRequest(functionName, params);
                });
        } else {
            return this.evadeRequest(functionName, params);
        }
    }

    private evadeRequest(functionName: string, params: any[]): Promise<any> {
        return new Promise(resolve => {
            switch (params.length) {
                case 0:
                    this.sso_window[functionName].call(null, resolve);
                    break;
                case 1:
                    this.sso_window[functionName].call(null, params[0], resolve);
                    break;
                case 2:
                    this.sso_window[functionName].call(null, params[0], params[1], resolve);
                    break;
                case 3:
                    this.sso_window[functionName].call(null, params[0], params[1], params[2], resolve);
                    break;
                case 4:
                    this.sso_window[functionName].call(null, params[0], params[2], params[3], params[4], resolve);
                    break;
            }
        });
    }

    signOn(user: string, password: string): Promise<any> {
        return this.dispatchRequest('signOn', [user, password]);
    }

    signOnAPP(user: string, password: string, appid: string): Promise<any> {
        return this.dispatchRequest('signOnAPP', [user, password, appid]);
    }


    signOnByAppToken(token: string): Promise<any> {
        return this.dispatchRequest('signOnByAppToken', [token]);
    }

    signOut(): Promise<any> {
        return this.dispatchRequest('signOut', []);
    }

    getUserData(): Promise<any> {
        return this.dispatchRequest('getUserData', []);
    }

    getUserToken(appId: string): Promise<any> {
        return this.dispatchRequest('getUserToken', [appId]);
    }

    setUserToken(appId: string, token: string): Promise<any> {
        return this.dispatchRequest('signOn', [appId, token]);
    }

    checkToken(appId: string): Promise<any> {
        return this.dispatchRequest('checkToken', [appId]);
    }
}