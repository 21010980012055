import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../services/global.service';
// import { HttpDataService } from '../services/http-data.service';
import { BsDatepickerConfig, BsDatepickerDirective, BsDatepickerViewMode, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker/public_api';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportService } from '../services/report.service';
import { Subscription } from 'rxjs';
import { ExportService } from '../services/export.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ReportName } from '../enum/report-name';
import { CalendarCellViewModel } from 'ngx-bootstrap/datepicker/models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'fade', opacity: 0 }),
        animate('1000ms', style({ transform: 'fade', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'fade', opacity: 1 }),
        animate('1000ms', style({ transform: 'fade', opacity: 0 }))
      ])]
    ),
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class DashboardComponent implements OnInit {
  // view: any[] = [1280, 400];
  // view2: any[] = [1280, 600];
  ColumnMode = ColumnMode;
  pathList: string[][];
  busy: Subscription;
  subscription: any;
  bsConfig: Partial<BsDatepickerConfig>;
  bsConfig1: Partial<BsDatepickerConfig>;
  bsConfig2: Partial<BsDatepickerConfig>;
  expression: Array<any> = [];
  switchfamily = false;
  familiesm = '0';
  famiglie: [];
  messaggio: string;
  tableData: any;
  @ViewChild('myTable') table: any;
  COLS = [];
  // rows = [];
  temp = [];
  minDate: Date;
  maxDate: Date;
  form: FormGroup;
  endMinDate: Date;
  startMaxDate: Date;
  reportEnum: typeof ReportName;
  // reportEnum: string[];

  constructor(
    public globals: GlobalService,
    private reportService: ReportService,
    private localeService: BsLocaleService,
    private exportService: ExportService,
    private fb: FormBuilder
  ) {
    this.pathList = [['Home', 'dashboard'], ['Home', 'dashboard']];
    this.localeService.use('it');
    this.reportEnum = ReportName;
  }

  isChecked(col) {
    return (
      this.tableData?.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.tableData.columns = this.tableData?.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.tableData.columns = [...this.tableData?.columns, col];
    }
  }

  export() {
    const a = this.form.get('families').value;
    console.log(ReportName[a]);
    let qs = new Date(this.form.get('querySTART').value);
    const data_da = qs.getFullYear() + (qs.getMonth() + 1).toString().padStart(2, '0') + qs.getDate().toString().padStart(2, '0')
    qs = new Date(this.form.get('queryEND').value);
    const data_a = qs.getFullYear() + (qs.getMonth() + 1).toString().padStart(2, '0') + qs.getDate().toString().padStart(2, '0')
    this.exportService.exportExcel(this.tableData.payload, ReportName[a] + '-' + data_da + '-' + data_a);
  }

  filterClear() {
    this.tableData.payload = this.temp;
    this.table.offset = 0;
  }


  updateFilter(event, field) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tableData.payload.filter(function (d) {
      return d[field] && d[field].toString().toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.tableData.payload = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  mobileHidden(value): string {
    return (value != "ordine" && value != "tipo" && value != "utente") ? "mobile-hidden" : "";
  }

  onClickCerca() {
    // console.log(this.form.controls);
    let qs = new Date(this.form.get('querySTART').value);
    const data_da = qs.getFullYear() + (qs.getMonth() + 1).toString().padStart(2, '0') + qs.getDate().toString().padStart(2, '0')

    qs = new Date(this.form.get('queryEND').value);
    let endDate = qs.getDate().toString().padStart(2, '0');
    if (this.form.controls['weekMonth'].value == 'month') {
      const d = new Date(qs.getFullYear(), qs.getMonth() + 1, 0);
      endDate = d.getDate().toString().padStart(2, '0');
    }
    const data_a = qs.getFullYear() + (qs.getMonth() + 1).toString().padStart(2, '0') + endDate

    this.busy = this.reportService.report(this.form.get('families').value, data_da, data_a).subscribe(
      (data) => {
        this.tableData = data;
        this.temp = [...data.payload];
        this.COLS = data.columns;
      },
      (err) => {
        alert("ERRORE: " + JSON.stringify(err));
      }
    );
  }

  private calcNextDD(dd: Date): Date {
    dd.setDate(dd.getDate() - dd.getDay()); // move to last sunday - dow 0
    return new Date(dd);
  }

  public onEndDateChange(value: Date) {
    this.startMaxDate = value;
  }

  public onStartDateChange(value: Date) {
    this.endMinDate = value;
  }

  private calcPrevDate(dd: Date): Date {
    let pd = new Date();
    pd.setDate(dd.getDate() - 6);
    return pd;
  }

  ngOnInit(): void {
    this.subscription = this.globals.dateChange.subscribe(() => { });
    this.bsConfig = this.bsConfig1;
    const x: BsDatepickerViewMode = 'day';
    const y: BsDatepickerViewMode = 'month';
    this.bsConfig1 = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      customTodayClass: 'custom-today-class',
      showWeekNumbers: true,
      dateInputFormat: 'DD/MM/YYYY',
      startView: x

    });
    this.bsConfig2 = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      customTodayClass: 'custom-today-class',
      showWeekNumbers: false,
      dateInputFormat: 'MM/YYYY',
      startView: y
    });
    const a = this.calcNextDD(new Date());
    // const b = this.calcPrevDate(a);

    this.minDate = new Date('2021/09/01');
    this.endMinDate = this.minDate;
    this.maxDate = new Date();
    this.startMaxDate = this.maxDate;

    this.form = this.fb.group({
      families: ['', Validators.required],
      ricerca: '',
      querySTART: this.calcPrevDate(a),
      queryEND: a,
      weekMonth: 'week'
    })
    this.form.controls['weekMonth'].valueChanges.subscribe(value => {
      console.log(value);
      if (value == "month") {
        this.bsConfig = this.bsConfig2;
      } else {
        this.bsConfig = this.bsConfig1;
      }
    });

  }

  onOpenCalendar(e) {
    if (this.form.controls['weekMonth'].value == 'month') {
      e.monthSelectHandler = (event: CalendarCellViewModel): void => {
        e._store.dispatch(e._actions.select(event.date));
        return;
      };
    }
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onResize(event) {
    // console.log(event.target.innerWidth);
    if (event.target.innerWidth <= 800) {
      this.tableData.columns = this.tableData.columns.filter(c => {
        return c.name == "ordine" || c.name == "tipo" || c.name == "utente";
      });
    } else {
      if (this.tableData && this.tableData.columns.length == 3) {
        this.tableData.columns = this.COLS;
      }
    }
  }

  // cAll(onoff) {
  //   // // console.log(this.expression);
  //   // for (const iterator of Object.keys(this.expression)) {
  //   //   this.expression[iterator] = onoff;
  //   // }
  // }

  // handleSwitchfamily($event) { }

  onSelectFamily(event) {
    console.log(this.familiesm, event)
  }

  weekMonthSelect(value) {
    if (value == "mese") {
      const x: BsDatepickerViewMode = 'month';
      this.bsConfig = Object.assign({}, {
        containerClass: 'theme-dark-blue',
        customTodayClass: 'custom-today-class',
        showWeekNumbers: false,
        startView: x
      });
    } else {
      this.bsConfig = Object.assign({}, {
        containerClass: 'theme-dark-blue',
        customTodayClass: 'custom-today-class',
        showWeekNumbers: false
      });
    }

  }
}
